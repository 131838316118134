.footer {
  .sitewide-footer-formatter {
    &__terms-links {
      text-align: #{$ldirection};
      width: 100%;
      font-size: 12px;
      @include breakpoint($landscape-up) {
        text-align: #{$rdirection};
      }
    }
    &__term-link {
      padding-#{$ldirection}: 0;
      display: block;
      @include breakpoint($medium-up) {
        padding-#{$ldirection}: 2%;
        display: inline-block;
      }
    }
    &__content {
      @include breakpoint($landscape-up) {
        flex: 0 66%;
      }
    }
    &__top {
      @include breakpoint($landscape-up) {
        flex: 0 34%;
      }
      .site-footer-email-signup {
        &__block--right,
        &__title,
        &__successful-message,
        &__email-content,
        &__sms,
        &__cta,
        &__gdpr-link {
          @include breakpoint($landscape-up) {
            width: 77%;
          }
        }
      }
      .footer-backtotop {
        bottom: 110px;
        @include breakpoint($landscape-up) {
          bottom: 85px;
        }
      }
    }
    .sticky-footer-chat {
      #{$rdirection}: 10px;
      top: auto;
      bottom: 5px;
    }
  }
}

.site-header {
  .gnav-header-block {
    &__header {
      @include breakpoint($landscape-up) {
        @include swap_direction(padding, 0 24px 0 24px);
      }
      @include breakpoint($xlarge-up) {
        @include swap_direction(padding, 0 44px 0 44px);
      }
    }
  }
  .gnav-header-sections {
    &__section-label {
      @include breakpoint($landscape-up) {
        font-size: 11px;
        letter-spacing: 0.05em;
        @include swap_direction(margin, 0 18px 0 0);
      }
      @include breakpoint($xlarge-up) {
        font-size: 12px;
        letter-spacing: 0.17em;
        @include swap_direction(margin, 0 22px 0 0);
      }
      @include breakpoint($xxlarge-up) {
        @include swap_direction(margin, 0 32px 0 0);
      }
      @include breakpoint($extralarge-up) {
        @include swap_direction(margin, 0 48px 0 0);
      }
    }
  }
}

.cs-contact-form-formatter {
  &__question-list {
    &.default-option {
      option {
        color: $color-black;
      }
    }
  }
}

.sd-appt-booking {
  .elc-appt-booking_stepper {
    margin-bottom: 24px;
  }
  .elc-appt-booking_calendar-container {
    .jinYCM {
      width: 50px;
    }
  }
}

#recommended-products-panel {
  .recommended-product-items {
    .recommended-item {
      .addtobag {
        @include breakpoint($medium-up) {
          padding-bottom: 10px;
        }
      }
    }
  }
}
